import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Grid, Row, Col } from "react-styled-flexboxgrid";

import ButtonLink from "../ButtonLink";
import Spacer from "../Spacer";
import Heading from "../typography/Heading";
import Text from "../typography/Text";
import VideoGridCard from "../VideoGridCard";

const videoOffset = 64;

const VideosContainer = styled.div`
  @media (min-width: ${(props) => props.theme.breakpoints.tablet}px) {
    display: grid;
    grid-template-rows: auto;
    grid-gap: 0;
    column-gap: 32px;
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: ${(props) => props.theme.breakpoints.tabletLandscape}px) {
    grid-template-columns: repeat(3, 1fr);

    > a:nth-child(2) {
      margin-top: ${videoOffset}px;
    }

    > a:nth-child(4) {
      margin-top: -${videoOffset}px;
    }

    > a:nth-child(6) {
      margin-top: -${videoOffset}px;
    }
  }
`;

const SmallButtonContainer = styled.div`
  display: block;
  margin-bottom: 60px;
  padding-top: 32px;

  @media (min-width: ${(props) => props.theme.breakpoints.tablet}px) {
    display: none;
  }
`;

const ButtonContainer = styled.div`
  display: none;

  @media (min-width: ${(props) => props.theme.breakpoints.tablet}px) {
    display: flex;
    justify-content: center;
    padding-bottom: 24px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.tabletLandscape}px) {
    justify-content: flex-end;
  }
`;

const Container = styled.section`
  position: relative;
  padding-top: 40px;
  background-color: ${(props) => props.theme.colors.white};
  z-index: ${(props) => props.theme.zIndex.section};
  width: 100%;

  @media (min-width: ${(props) => props.theme.breakpoints.mobile}px) {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.tablet}px) {
    padding-top: 80px;
    padding-bottom: 80px;
    background-color: ${(props) =>
      props.showShape ? "transparent" : props.theme.colors.white};
  }

  @media (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    padding-top: 120px;
    padding-bottom: 120px;
  }
`;

const VideoGridBlock = ({
  videos,
  heading,
  text,
  ctaText,
  ctaUrl,
  type,
  hideCta,
  category
}) => {
  return (
    <Container>
      <Grid>
        <Row bottom="md">
          <Col xs={12} md={8}>
            <Spacer margin={{ xs: "0 0 24px", s: "0 0 68px" }}>
              <Heading size="l" as="h2" margin="0 0 xl">
                {heading}
              </Heading>
              {text && (
                <Text size="l" margin="0">
                  {text}
                </Text>
              )}
            </Spacer>
          </Col>
          <Col xs={12} md={4}>
            {!hideCta && (
              <ButtonContainer>
                <ButtonLink
                  title={ctaText}
                  to={ctaUrl}
                  variant="secondary"
                  margin="0 0 xxl"
                />
              </ButtonContainer>
            )}
          </Col>
        </Row>
        {videos && (
          <Row>
            <Col xs={12}>
              <VideosContainer>
                {videos.map((video, i) => {
                  return (
                    <VideoGridCard
                      key={`video__${i}`}
                      type={type}
                      category={category}
                      {...video.node}
                    />
                  );
                })}
              </VideosContainer>
            </Col>
          </Row>
        )}
        {!hideCta && (
          <Row>
            <Col xs={12}>
              <SmallButtonContainer>
                <ButtonLink
                  title={ctaText}
                  to={ctaUrl}
                  variant="secondary"
                  margin="0 0 xxl"
                />
              </SmallButtonContainer>
            </Col>
          </Row>
        )}
      </Grid>
    </Container>
  );
};

VideoGridBlock.propTypes = {
  videos: PropTypes.array.isRequired,
  heading: PropTypes.string.isRequired,
  text: PropTypes.string,
  type: PropTypes.oneOf(["portrait", "landscape"]),
  ctaText: PropTypes.string.isRequired,
  ctaUrl: PropTypes.string.isRequired,
};

export default VideoGridBlock;
