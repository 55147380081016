import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import close from "../../images/icons/close.svg";

const ModalWrapper = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  top: 0;
  left: 0;
  padding: 50px 20px 20px;
  z-index: 120;

  @media (min-width: ${props => props.theme.breakpoints.tablet}px) {
    padding: 60px;
  }

  @media (min-width: ${props => props.theme.breakpoints.desktop}px) {
    padding: 100px;
  }
`;

const ModalOverlay = styled.div`
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  background-color: ${props => props.theme.colors.overlay};
  z-index: 120;
  pointer-events: all;
`;

/* 

export const breakpoints = {
  mobile: 480,
  tablet: 768,
  tabletLandscape: 1024,
  desktop: 1200,
  desktopLarge: 1440,
};
*/

const ModalContent = styled.section`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: ${(props) => (props.isDark ? "black" : "white")};
  z-index: 120;
  max-height: 400px;

  @media (min-width: ${props => props.theme.breakpoints.tablet}px) {
    max-width: 550px;
  }
 
  @media (min-width: ${props => props.theme.breakpoints.desktop}px) {
    flex-direction: row;
    max-height: 480px;
    max-width: 853px;
    margin-top: 80px;
  }
`;

const AspectRatioContent = styled.section`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  padding-top: ${props => props.aspectRatio};
  background-color: ${props => props.theme.colors.primary};
  z-index: 120;

  @media (min-width: ${props => props.theme.breakpoints.desktop}px) {
    flex-direction: row;
    max-width: 80vw;
  }

  @media (min-width: 1600px) {
    padding-top: 780px;
    max-width: 1400px;
  }
`;

const CancelButton = styled.div`
  position: absolute;
  width: 15px;
  height: 15px;
  top: -30px;
  right: 0;
  cursor: pointer;

  background-image: url(${close});
  background-size: 100%;
  background-repeat: no-repeat;
  margin: 0 auto;
  display: block;

  @media (min-width: ${props => props.theme.breakpoints.desktop}px) {
    width: 20px;
    height: 20px;
    top: -50px;
  }
`;

const Modal = ({ children, closeModal, aspectRatio, isDark }) => {
  return (
    <ModalWrapper aspectRatio={aspectRatio}>
      <ModalOverlay onClick={closeModal} />
      {aspectRatio ? (
        <AspectRatioContent aspectRatio={aspectRatio}>
          <CancelButton onClick={closeModal} />
          {children}
        </AspectRatioContent>
      ) : (
        <ModalContent isDark={isDark}>
          <CancelButton onClick={closeModal} />
          {children}
        </ModalContent>
      )}
    </ModalWrapper>
  );
};

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  closeModal: PropTypes.func,
  aspectRatio: PropTypes.string,
  isDark: PropTypes.bool,
};

export default Modal;
