import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

import chevronBlue from "../../images/icons/chevron-right_blue.svg";
import chevronWhite from "../../images/icons/chevron-right_white.svg";
import { withMargin } from "../../theme/spacing";
import { makeAbsolute } from "../../utils/content";

const Container = styled.a`
  position: relative;
  display: inline-block;
  font-size: ${props => (props.variant === "primary" ? "22px" : "18px")};
  padding: ${props => (props.variant === "primary" ? "16px 32px" : "16px")};
  background-color: ${props =>
    props.variant === "primary" || props.variant === "secondary"
      ? props.theme.colors.blue
      : props.theme.colors.white};
  font-weight: ${props =>
    props.variant === "primary" && !props.condensed ? 600 : 400};
  text-transform: uppercase;
  letter-spacing: 0.6px;
  text-align: center;
  outline: none;
  border: none;
  border-radius: 0;
  transition: background 100ms ease-in-out 0s;
  text-decoration: none;
  color: ${props =>
    props.variant === "primary" || props.variant === "secondary"
      ? props.theme.colors.white
      : props.theme.colors.blue};

  ${props =>
    props.condensed &&
    css`
      font-size: 14px;
      padding: 14px 24px;
      font-weight: 400;
    `}

  ${props =>
    props.variant !== "primary" &&
    css`
      ${props => props.variant === "back" && "padding-left: 38px;"}
      ${props =>
        props.variant !== "back" && "padding-right: 38px;"}

      &::after {
        position: absolute;
        top: calc(50% - 11px);
        ${props => (props.variant === "back" ? "left: 16px;" : "right: 16px;")}
        width: 8px;
        height: 22px;
        display: block;
        ${props => props.variant === "back" && "transform: rotate(180deg);"};
        content: url(${props =>
          props.variant === "secondary" ? chevronWhite : chevronBlue});
        transition: transform 100ms ease-in-out 0s;
      }
    `}

  &:hover {
    background-color: ${props =>
      props.variant === "primary" || props.variant === "secondary"
        ? props.theme.colors.blueHover
        : "rgba(250, 250, 250, .8)"};

    &::after {
      transform: ${props =>
        props.variant === "back"
          ? "rotate(180deg) translateX(9px)"
          : "translateX(9px)"};
    }
  }

  ${props => withMargin(props)}
`;

const ButtonLink = ({
  title,
  to,
  target,
  margin,
  variant = "tertiary",
  condensed = false,
  ...restProps
}) => (
  <Container
    href={makeAbsolute(to)}
    target={target}
    variant={variant}
    margin={margin}
    condensed={condensed}
    {...restProps}
  >
    {title}
  </Container>
);

ButtonLink.propTypes = {
  variant: PropTypes.oneOf(["primary", "secondary", "tertiary", "back"]),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  to: PropTypes.string.isRequired,
  target: PropTypes.string,
  condensed: PropTypes.bool,
};

export default ButtonLink;
