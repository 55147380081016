import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import styled from "styled-components";
import { graphql, StaticQuery } from "gatsby";
import { useRecoilValue } from "recoil";

import { browserState } from "../components/new/ScrollAware";
import Layout from "../components/new/Layout";
import FixedBlock from "../components/new/blocks/FixedBlock";
import HeroBlock from "../components/new/blocks/HeroBlock";
import CalloutBlock from "../components/new/blocks/Calloutblock";
import DiversityBlock from "../components/new/blocks/DiversityBlock";
import VideoGridBlock from "../components/new/blocks/VideoGridBlock";

import { extractSocialMetadata } from "../utils/content";
import theme from "../theme/";

const CareersPage = ({ data }) => {
  const { isIE } = useRecoilValue(browserState);
  const content = data.prismic.allCareerss.edges[0].node;
  const meta = data.site.siteMetadata;

  const videoCards = data.prismic.allVideo_cards.edges.sort(
    (a, b) => a.node.featured_order - b.node.featured_order
  );

  if (!content) {
    return null;
  }

  const [hasSocialMetaData, socialMetadata] = extractSocialMetadata(
    content.body1
  );

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: "en",
        }}
        title={content.meta_title}
        meta={[
          {
            name: `description`,
            content: content.meta_description,
          },
          {
            property: `og:title`,
            content: hasSocialMetaData
              ? socialMetadata.general_card.title
              : meta.title,
          },
          {
            property: `og:description`,
            content: hasSocialMetaData
              ? socialMetadata.general_card.description
              : content.meta_description,
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            property: `og:image`,
            content: hasSocialMetaData && socialMetadata.general_card.image,
          },
          {
            name: `twitter:card`,
            content: `summary`,
          },
          {
            name: `twitter:creator`,
            content: meta.author,
          },
          {
            name: `twitter:site`,
            content: hasSocialMetaData && socialMetadata.twitter_handle,
          },
          {
            name: `twitter:title`,
            content: hasSocialMetaData
              ? socialMetadata.twitter_card.title
              : meta.title,
          },
          {
            name: `twitter:description`,
            content: hasSocialMetaData
              ? socialMetadata.twitter_card.description
              : meta.description,
          },
          {
            name: `twitter:image`,
            content: hasSocialMetaData && socialMetadata.twitter_card.image,
          },
        ].concat(meta)}
      />
      <Layout>
        {content.body.map((section, i) => {
          const key = `${section.type}_${i}`;
          const { type, primary: data } = section;

          switch (type) {
            case "image_hero":
              return (
                <FixedBlock
                  index={isIE ? theme.zIndex.background : theme.zIndex.swipe}
                  key={key}
                >
                  <HeroBlock
                    image={data.image_hero_background}
                    title={data.image_hero_heading}
                  />
                </FixedBlock>
              );
            case "middle_section":
              return (
                <DiversityBlock
                  key={key}
                  intro={data.into_copy}
                  introHeading={data.intro_copy_heading}
                  introCta={data.intro_copy_cta}
                  heading={data.body_copy_header}
                  body={data.body_copy_paragraph}
                  background={data.main_image}
                />
              );
            case "call_out_row":
              return (
                <CalloutBlock
                  key={key}
                  body={data.main_copy}
                  cta={data.cta_button_copy}
                  target={
                    data.cta_button_target_internal ||
                    data.cta_button_target_external
                  }
                  internal={!!data.cta_button_target_internal}
                />
              );
            case "video_grid":
              return (
                <VideoGridBlock
                  heading={data.heading}
                  ctaText={data.cta_text}
                  ctaUrl={data.cta_link}
                  category="join-us"
                  type="portrait"
                  videos={videoCards}
                  hideCta={false}
                />
              );
          }
        })}
      </Layout>
    </>
  );
};

CareersPage.propTypes = {
  data: PropTypes.object.isRequired,
};

const query = graphql`
  {
    prismic {
      allCareerss {
        edges {
          node {
            meta_title
            meta_description
            body {
              ... on PRISMIC_CareersBodyImage_hero {
                type
                label
                primary {
                  image_hero_heading
                  image_hero_background
                }
              }
              ... on PRISMIC_CareersBodyMiddle_section {
                type
                label
                primary {
                  into_copy
                  intro_copy_heading
                  body_copy_header
                  body_copy_paragraph
                  main_image
                }
              }
              ... on PRISMIC_CareersBodyCall_out_row {
                type
                label
                primary {
                  main_copy
                  cta_button_target_internal
                  cta_button_target_external
                  cta_button_copy
                }
              }
              ... on PRISMIC_CareersBodyVideo_grid {
                type
                label
                primary {
                  cta_link
                  cta_text
                  heading
                  text
                }
              }
            }
            body1 {
              ... on PRISMIC_CareersBody1General_card {
                type
                label
                primary {
                  description
                  image
                  title
                }
              }
              ... on PRISMIC_CareersBody1Twitter_card {
                type
                label
                primary {
                  card_type
                  description
                  image
                  title
                  twitter_handle
                }
              }
            }
          }
        }
      }
      allVideo_cards(
        lang: "en-gb"
        where: { featured: true, type: "Join Us" }
      ) {
        edges {
          node {
            short_copy
            card_image_portrait
            video_id
            hash_id
            featured_order
          }
        }
      }
    }
    site {
      siteMetadata {
        title
        description
        author
      }
    }
  }
`;

const CareersData = () => (
  <StaticQuery
    query={`${query}`}
    render={(data) => <CareersPage data={data} />}
  />
);

export default CareersData;
