import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Grid, Row, Col } from "react-styled-flexboxgrid";
import { RichText } from "prismic-reactjs";

import Spacer from "../Spacer";
import ButtonLink from "../ButtonLink";

const Container = styled.section`
  background-color: ${(props) => props.theme.colors.blue};
  position: relative;
  z-index: ${props => props.theme.zIndex.section};

  @media (min-width: ${props => props.theme.breakpoints.tablet}px) {
    background-image: linear-gradient(60deg, ${(props) => props.theme.colors.blueHover} 20%, ${(props) => props.theme.colors.blue} 20%);
  }
`;

const Content = styled.div`
  color: ${(props) => props.theme.colors.white};
  padding: 83px 0 54px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  white-space: pre-wrap;

  h3 {
    margin-bottom: ${props => props.theme.spacing.xxl};
    width: 100%;
  }

  h4 {
    font-weight: 400;
    margin-bottom: ${props => props.theme.spacing.xxl};
  }

  @media (min-width: ${props => props.theme.breakpoints.tablet}px) {

    h3 {
      margin-bottom: ${props => props.theme.spacing.xxl};
    }

    h4 {
      margin-bottom: ${props => props.theme.spacing.xxl};
    }
  }
`;

const CalloutBlock = ({ body, cta, target, internal = true }) => (
  <Container>
    <Grid>
      <Row>
        <Col xs={12} mdOffset={1} md={10} lgOffset={1} lg={9}>
          <Content>
            {RichText.render(body)}
            {cta && target && (
              <ButtonLink
                margin="xl 0"
                title={cta}
                variant="tertiary"
                to={target}
                target={internal ? null : "_blank"}
                rel={internal ? null : "noopener noreferrer"}
              />
            )}
          </Content>
        </Col>
      </Row>
    </Grid>
  </Container>
);

CalloutBlock.propTypes = {
  body: PropTypes.array.isRequired,
  cta: PropTypes.string,
  target: PropTypes.string,
  internal: PropTypes.bool,
};

export default CalloutBlock;
