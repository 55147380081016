import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Grid, Row, Col } from "react-styled-flexboxgrid";
import { RichText } from "prismic-reactjs";

import Spacer from "../Spacer";
import Heading from "../typography/Heading";
import ButtonLink from "../ButtonLink";

const Container = styled.section`
  position: relative;
  z-index: ${(props) => props.theme.zIndex.section};

  p {
    margin-bottom: 16px;
    font-size: 18px;

    @media (min-width: ${(props) => props.theme.breakpoints.tablet}px) {
      font-size: 22px;
      line-height: 1.4;
    }

    @media (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
      font-size: 24px;
    }
  }
`;

const Content = styled.div`
  padding: 40px 0;
  position: relative;

  @media (min-width: ${(props) => props.theme.breakpoints.tablet}px) {
    padding-top: 50px;
    padding-bottom: 100px;
    margin-top: -230px;
    width: 389px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.tabletLandscape}px) {
    margin-left: 77px;
    margin-top: -270px;
    width: 439px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    margin-top: -325px;
    margin-left: 97px;
    width: 555px;
  }
`;

const Background = styled.section`
  position: relative;
  width: 100%;
  padding-top: 100%;
  overflow: hidden;
  background-image: url(${(props) => props.background});
  background-repeat: no-repeat;
  background-size: cover;

  @media (min-width: ${(props) => props.theme.breakpoints.tablet}px) {
    background-size: contain;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    height: 800px;
  }
`;

const Diversity = ({ intro, introHeading, background, heading, body }) => (
  <Spacer padding={{ xs: "0 0 0px", s: "0 0 0px" }}>
    <Container>
      <Grid>
        {intro && (
          <Row>
            <Col xs={12} mdOffset={1} md={10} lgOffset={1} lg={9}>
              <Heading size="l" as="h2" margin="0">
                {introHeading}
              </Heading>
              <Spacer padding={{ xs: "24px 0", lg: "180px 0 100px" }}>
                {RichText.render(intro)}
                <ButtonLink
                  title="Contact Us"
                  to="/contact"
                  variant="secondary"
                  margin="l 0 xl"
                />
              </Spacer>
            </Col>
          </Row>
        )}
        <Row>
          {background && (
            <Col xs={12} mdOffset={1} md={10}>
              <Background background={background.url} />
            </Col>
          )}
          <Col xs={12} md={8} lg={6}>
            <Content>
              <Heading size="l" as="h2" margin="0 0 30px">
                {heading}
              </Heading>
              <p>{body}</p>
            </Content>
          </Col>
        </Row>
      </Grid>
    </Container>
  </Spacer>
);

Diversity.propTypes = {
  intro: PropTypes.array,
  introHeading: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  background: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }),
};

export default Diversity;
