import React, { useState, useEffect } from "react";

import PropTypes from "prop-types";
import styled from "styled-components";

import Modal from "./Modal";
import Text from "./typography/Text";

import videoIco from "../../images/icons/video-icon.svg";
import playIco from "../../images/icons/hover-play.svg";

const transition = "0.5s ease";

const VideoLink = styled.a`
  &:hover {
    > div {
      &::before,
      &::after {
        opacity: 1;
      }
      i {
        opacity: 0;
      }
    }
  }
`;

const Icon = styled.i`
  background-image: url(${videoIco});
  background-size: 25px 22px;
  background-repeat: no-repeat;
  position: absolute;
  opacity: 1;
  top: 16px;
  right: 14px;
  width: 25px;
  height: 22px;
  z-index: 10;
  transition: opacity ${transition};
`;

const Image = styled.div`
  background-color: black;
  background-image: url(${(props) => props.image});
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 0;
  position: relative;
  width: 100%;
  height: ${(props) => (props.type === "landscape" ? "200px;" : "610px")};
  padding-bottom: calc(192 / 340 * 100%);

  &::before {
    background-color: rgba(0, 0, 0, 0.5);
    content: " ";
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    transition: opacity ${transition};
  }

  &::after {
    background-image: url(${playIco});
    background-repeat: no-repeat;
    background-size: 120px;
    content: " ";
    position: absolute;
    opacity: 0;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 20;
    width: 121px;
    height: 120px;
    transition: opacity ${transition};
  }
`;

const VideoIFrameContainer = styled.div`
  position: relative;
  display: block;
  width: 90%;
  height: 0;
  margin: auto;
  padding: 0% 0% 56.25%;
  overflow: hidden;
`;

const VideoIFrame = styled.iframe`
  position: absolute;
  top: 0;
  border: 0;
`;

const VideoGridItem = ({
  card_image_landscape,
  card_image_portrait,
  short_copy,
  video_id,
  hash_id,
  type,
  category
}) => {
  const [showModal, setShowModal] = useState(false);
  const [firstHash, setFirstHash] = useState(false);

  const browserWindow = typeof window !== "undefined" ? window : undefined;

  useEffect(() => {
    if (!browserWindow) {
      return;
    }
    if (browserWindow.location.hash === `#${hash_id}` && !firstHash) {
      setShowModal(true);
      setFirstHash(true);
      browserWindow.scrollTo({
        top: browserWindow.document.body.scrollHeight / 2,
        behavior: "smooth",
      });
    }
  }, [browserWindow]);

  let imageUrl =
    type === "landscape" ? card_image_landscape : card_image_portrait;

  if (!imageUrl) {
    imageUrl =
      "data:image/gif;base64,R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=";
  }

  const openVideo = (e) => {
    e.preventDefault();
    setShowModal(true);
  };

  return (
    <>
      <VideoLink
        id={`#${hash_id}`}
        data-category={category}
        onClick={(e) => openVideo(e)}
        to="#"
      >
        <Image image={imageUrl.url} type={type}>
          <Icon />
        </Image>
        <Text colour="grey03" size="l" margin="xl 0 xxl">
          {short_copy[0].text}
        </Text>
      </VideoLink>
      {showModal && (
        <Modal closeModal={() => setShowModal(false)} isDark={true}>
          <VideoIFrameContainer>
            <VideoIFrame
              width="100%"
              height="100%"
              src={"https://www.youtube.com/embed/" + video_id}
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            />
          </VideoIFrameContainer>
        </Modal>
      )}
    </>
  );
};

export default VideoGridItem;

VideoGridItem.propTypes = {
  card_image_landscape: PropTypes.object,
  card_image_portrait: PropTypes.object,
  short_copy: PropTypes.array.isRequired,
  video_id: PropTypes.string.isRequired,
};
