import React, { useRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { useRecoilValue } from "recoil";
import { Grid, Row, Col } from "react-styled-flexboxgrid";

import Header from "../typography/Heading";
import FullScreenVideo from "../FullScreenVideo";
import Swipe from "../Swipe";
import play from "../../../images/icons/play.svg";

import {
  browserState,
  viewportState,
  clipState,
  getWindowScroll,
} from "../ScrollAware";

const Container = styled.section`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  z-index: ${(props) => props.theme.zIndex.section};
  clip-path: url(#overlay-shape);
`;

const Video = styled.video`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: auto;
  height: auto;
  object-fit: cover;
  min-width: 100%;
  min-height: 100%;
  z-index: ${(props) => props.theme.zIndex.background + 1};
`;

const Image = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: auto;
  height: auto;
  min-width: 100%;
  min-height: 100%;
  background-image: url(${(props) => props.background});
  background-size: cover;
  background-repeat: no-repeat;
  z-index: ${(props) => props.theme.zIndex.background};
`;

const Content = styled.div`
  z-index: ${(props) => props.theme.zIndex.content};
  color: ${(props) => props.theme.colors.white};
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
`;

const PlayButton = styled.button`
  height: 80px;
  width: 80px;
  border-radius: 60px;
  background-color: ${(props) => props.theme.colors.white};
  background-image: url(${play});
  background-size: 100%;
  background-repeat: no-repeat;
  margin: 0 auto;
  display: block;
  cursor: pointer;
  outline: 0;
  border: 0;
  transition: transform 100ms ease-in-out;

  &:hover {
    transform: scale(1.1);
  }

  @media (min-width: ${(props) => props.theme.desktop}px) {
    height: 120px;
    width: 120px;
  }
`;

const LargeTitle = styled.div`
  margin-left: 85px;
  @media (min-width: ${(props) => props.theme.breakpoints.tablet}px) {
    margin-left: 0;
  }
`;

const HeroBlock = ({
  title,
  subtitle,
  largeTitle,
  image,
  preview,
  video,
  grey = false,
}) => {
  const { width, height } = useRecoilValue(viewportState);
  const { isIE, isSafari, isMobile } = useRecoilValue(browserState);
  const { multiplier } = useRecoilValue(clipState);
  const [showVideo, setShowVideo] = useState(false);
  const [initialX, setInitialX] = useState(0);
  const [disableContent, setDisableContent] = useState(false);
  const swipeRef = useRef();
  const videoRef = useRef();

  const onScroll = () => {
    const scrollY = Math.max(0, getWindowScroll().y);

    if (scrollY > height / 2) {
      setDisableContent(true);
    } else {
      setDisableContent(false);
    }

    if (videoRef.current) {
      if (scrollY >= height / 2) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
    }
  };

  useEffect(() => {
    document.addEventListener("scroll", onScroll);

    return () => {
      document.removeEventListener("scroll", onScroll);
    };
  }, [videoRef, multiplier, onScroll, height]);

  useEffect(() => {
    if (!videoRef.current) {
      return;
    }

    try {
      if (showVideo) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
    } catch (e) {}
  }, [showVideo, video, videoRef, isSafari, isMobile]);

  const startVideo = () => {
    setShowVideo(true);
  };

  const stopVideo = () => {
    setShowVideo(false);
  };

  return (
    <Container isIE={isIE}>
      <Content disabled={disableContent}>
        {title && (
          <Grid>
            <Row>
              <Col xs={12} mdOffset={1} md={10}>
                <Header light>{title}</Header>
                {subtitle && (
                  <Header margin="xxl 0 0" light>
                    {subtitle}
                  </Header>
                )}
                {largeTitle && (
                  <Header size="xxl" margin="xxl 0 0" light>
                    <LargeTitle>{largeTitle}</LargeTitle>
                  </Header>
                )}
              </Col>
            </Row>
          </Grid>
        )}
        {video && <PlayButton onClick={startVideo} />}
      </Content>

      {preview && !isSafari && !isMobile && (
        <Video
          ref={videoRef}
          muted={true}
          autoPlay={true}
          preload="auto"
          loop={true}
          playsInline
          poster={image && image.url}
          controls
        >
          <source src={preview.url} type="video/mp4" />
        </Video>
      )}
      {video && (
        <FullScreenVideo
          video={video}
          onClick={stopVideo}
          playing={showVideo}
        />
      )}

      {image && <Image background={image.url} />}

      <Swipe notch={true} />
    </Container>
  );
};

HeroBlock.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  largeTitle: PropTypes.string,
  preview: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }),
  video: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }),
  image: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }),
  grey: PropTypes.bool,
};

export default HeroBlock;
