import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { withMargin, withPadding } from "../../../theme/spacing";

import slashSvg from "../../../images/icons/slash.svg";

const Paragraph = styled.p`
  position: relative;
  font-size: ${props =>
    props.size === "s" ? "11px" : props.size === "l" ? "18px" : "14px"};
  color: ${props =>
    props.colour
      ? props.theme.colors[props.colour]
      : props.theme.colors.primary};
  ${props => props.bold && `font-weight: 700;`};
  text-align: ${props => (props.align ? props.align : "left")};

  ${props => withMargin(props)}
  ${props => withPadding(props)}

  ${props =>
    props.showSlash &&
    css`
      &::after {
        content: url(${slashSvg});
        position: absolute;
        margin-left: 20px;
        margin-top: 5px;
        transform: rotate(-5deg);
      }
    `}

  @media (min-width: ${props => props.theme.breakpoints.tablet}px) {
    font-size: ${props =>
      props.size === "s" ? "12px" : props.size === "l" ? "22px" : "16px"};
  }

  @media (min-width: ${props => props.theme.breakpoints.desktop}px) {
    font-size: ${props =>
      props.size === "s" ? "12px" : props.size === "l" ? "24px" : "18px"};
  }
`;

const Text = ({
  children,
  align = "left",
  size = "m",
  bold = false,
  padding,
  margin,
  showSlash = false,
  ...restProps
}) => (
  <Paragraph
    size={size}
    bold={bold}
    align={align}
    padding={padding}
    margin={margin}
    showSlash={showSlash}
    {...restProps}
  >
    {children}
  </Paragraph>
);

Text.propTypes = {
  children: PropTypes.node.isRequired,
  align: PropTypes.oneOf(["left", "center", "right"]),
  size: PropTypes.oneOf(["s", "m", "l"]),
  bold: PropTypes.bool,
  padding: PropTypes.string,
  margin: PropTypes.string,
  showSlash: PropTypes.bool,
};

export default Text;
